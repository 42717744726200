import './App.css';
import TodayList from "./components/TodayList";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <TodayList></TodayList>
      </header>
    </div>
  );
}

export default App;
